<template>
  <div>
    <b-card>
      <div class="m-2">
        <div class="row d-flex flex-column align-items-start">
          <h2 class="ml-2 font-weight-bold">{{ policy.group_name }}</h2>
          <div class="ml-2 d-inline-block text-truncate text-primary" style="max-width: 200px">
            <p>{{ policy.group_description }}</p>
          </div>
        </div>
      </div>
    </b-card>

    <b-card>
      <div class="m-2">
        <div class="" >
          <h2 class="ml-1 font-weight-bold">Template Details</h2>
          <table role="table" class="table b-table mt-1">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">SL No:</th>
                <th role="columnheader" scope="col">Template Name</th>
              </tr>
            </thead>
            <tbody role="rowgroup" v-if="paginatedTemplates.length > 0">
              <tr v-for="(pol, index) in paginatedTemplates" :key="pol.policy_id" role="row" class="cursor-pointer">
                <td aria-colindex="1" role="cell" style="max-width: 20vw">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                <td aria-colindex="1" role="cell" style="max-width: 20vw">
                  <div class="d-flex flex-column align-items-start justify-content-center w-100">
                    <p class="mb-0">{{ pol.template_detail.template_name }}</p>
                  </div>
                </td>
              </tr>
            </tbody>
            <template v-else>
              <empty-table-section title="Templates Not Found">
                <template #content>
                  <p class="font-weight-bold text-center">It seems like there are no templates at the moment.</p>
                </template>
              </empty-table-section>
            </template>
          </table>
          <!-- Pagination Controls -->
           <div class="d-flex justify-content-end allign-items-end">

             <b-pagination
               v-model="currentPage"
               :total-rows="policy.templates.length"
               :per-page="itemsPerPage"
               class="mt-2 ml-auto"
             ></b-pagination>
           </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCol, BPagination } from 'bootstrap-vue';
import EmptyTableSection from "@/components/EmptyTableSection.vue";

export default {
  components: {
    BCard,
    BCol,
    BPagination,
    EmptyTableSection
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10 // Adjust the number of items per page as needed
    };
  },
  computed: {
    policy() {
      return this.$store.state.policies.policy;
    },
    paginatedTemplates() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.policy.templates.slice(start, end);
    }
  },
  mounted() {
    console.log('policy', this.policy);
  }
};
</script>

